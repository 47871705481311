import { AppSettings } from "./interfaces/App-Settings";

const hostname = window.location.hostname;

const appSettings: AppSettings = {
    apiPath:
        hostname === 'localhost' ? "https://localhost:7189" :
            hostname === 'dev-chilidoc.chili.ch' ? "https://api.dev-chilidoc.chili.ch" :
            "https://api.chilidoc.chili.ch/",
};

export default appSettings;